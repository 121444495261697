import React from 'react'
import EquipmentList from './EquipmentList.js'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core';

const categoryFields = {
  vehicle: {
    vin: { type: String, required: true },
    plate: { type: String, required: true },
    make: { type: String, required: true },
    model: { type: String, required: true },
    year: { type: Number, required: true },
    mileage: Number,
    fuelType: String,
    registrationExpiryDate: Date,
    insuranceExpiryDate: Date,
    dotNumber: String,
    gpsTrackerId: String,
    vehicleClass: String,
    requiredLicense: String // e.g., CDL-A, CDL-B
  },

  trailer: {
    vin: { type: String, required: true },
    plate: { type: String, required: true },
    make: String,
    model: String,
    year: Number,
    weightCapacity: Number,
    length: Number,
    width: Number,
    height: Number,
    axles: Number,
    brakeType: String,
    registrationExpiryDate: Date
  },

  powerEquipment: {
    serialNumber: { type: String, required: true },
    make: { type: String, required: true },
    model: { type: String, required: true },
    year: Number,
    engineHours: Number,
    engineSize: String,
    fuelType: String,
    oilType: String,
    batteryType: String,
    powerOutput: String,
    weightCapacity: Number
  },

  cellPhone: {
    manufacturer: { type: String, required: true },
    model: { type: String, required: true },
    serialNumber: String,
    imei: { type: String, required: true },
    phoneNumber: { type: String, required: true },
    mac: String,
    carrier: String,
    planType: String,
    fcmToken: String,
    lastBackupDate: Date
  },

  computer: {
    manufacturer: { type: String, required: true },
    model: { type: String, required: true },
    serialNumber: { type: String, required: true },
    mac: String,
    operatingSystem: String,
    processorType: String,
    ramGB: Number,
    storageGB: Number,
    lastBackupDate: Date
  },

  powerTool: {
    serialNumber: { type: String, required: true },
    make: { type: String, required: true },
    model: { type: String, required: true },
    powerType: String, // Battery, Gas, Electric
    batteryType: String,
    voltage: String,
    engineSize: String
  },

  handTool: {
    make: String,
    model: String,
    serialNumber: String,
    size: String,
    material: String
  },

  safety: {
    certificationNumber: String,
    certificationExpiry: Date,
    standardsCompliance: [String],
    inspectionFrequency: String,
    lastInspectionDate: Date
  }
}

const Equipment = (props) => {
  // console.log('Serivce Component', props)

  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Equipment</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={12} xs={12}>
        <EquipmentList categoryFields={categoryFields} />
        </Grid>
      </Grid>
      
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(Equipment)