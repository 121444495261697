import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Collapse,
  Box,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  KeyboardArrowDown as ExpandMoreIcon,
  KeyboardArrowUp as ExpandLessIcon,
  Build as BuildIcon,
  Speed as SpeedIcon,
  CalendarToday as CalendarIcon,
  Timer as TimerIcon,
} from '@material-ui/icons';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  header: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
  },
  summaryCards: {
    marginBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
  },
  cardIcon: {
    fontSize: 40,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  tableContainer: {
    maxHeight: 400,
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  expandedRow: {
    backgroundColor: theme.palette.action.hover,
  },
  detailsBox: {
    padding: theme.spacing(2),
  },
  detailsGrid: {
    marginTop: theme.spacing(1),
  },
  serviceLabel: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  serviceValue: {
    fontWeight: 400,
  },
  noRecords: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const MaintenanceRow = ({ record, classes }) => {
  const [expanded, setExpanded] = useState(false);

  const getServicesPerformed = (services) => {
    return Object.entries(services)
      .filter(([_, service]) => service.performed === 'yes')
      .map(([key, service]) => ({
        name: key.replace(/([A-Z])/g, ' $1').trim(),
        details: service
      }));
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{moment(record.serviceDate).format('MM/DD/YYYY')}</TableCell>
        <TableCell>{record.currentMileage.toLocaleString()} mi</TableCell>
        <TableCell>{record.nextServiceMileage.toLocaleString()} mi</TableCell>
        <TableCell>
          {getServicesPerformed(record.services).slice(0, 2).map((service, index) => (
            <Chip
              key={index}
              label={formatLabel(service.name)}
              size="small"
              className={classes.chip}
            />
          ))}
          {getServicesPerformed(record.services).length > 2 && (
            <Chip
              label={`+${getServicesPerformed(record.services).length - 2} more`}
              size="small"
              className={classes.chip}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box className={classes.detailsBox}>
              <Typography variant="h6" gutterBottom>
                Service Details
              </Typography>
              <Grid container spacing={2} className={classes.detailsGrid}>
                {getServicesPerformed(record.services).map((service, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Typography className={classes.serviceLabel}>
                      {formatLabel(service.name)}
                    </Typography>
                    {service.details.notes && (
                      <Typography variant="body2" className={classes.serviceValue}>
                        Notes: {service.details.notes}
                      </Typography>
                    )}
                    {service.details.oilType && (
                      <Typography variant="body2" className={classes.serviceValue}>
                        Oil Type: {service.details.oilType}
                      </Typography>
                    )}
                    {service.details.filterType && (
                      <Typography variant="body2" className={classes.serviceValue}>
                        Filter: {service.details.filterType}
                      </Typography>
                    )}
                  </Grid>
                ))}
              </Grid>
              
              {record.tireReadings && (
                <>
                  <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
                    Tire & Brake Readings
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(record.tireReadings).map(([position, data]) => (
                      <Grid item xs={12} sm={6} md={3} key={position}>
                        <Typography className={classes.serviceLabel}>
                          {formatLabel(position)}
                        </Typography>
                        <Typography variant="body2" className={classes.serviceValue}>
                          Tire: {data.tireCondition || 'Not recorded'}
                        </Typography>
                        <Typography variant="body2" className={classes.serviceValue}>
                          Brakes: {data.brakePadCondition || 'Not recorded'}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const EquipmentMaintenanceHistory = ({ equipmentId, url }) => {
  const classes = useStyles();
  const [maintenanceHistory, setMaintenanceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalServices: 0,
    averageMileage: 0,
    lastService: null,
    nextServiceDue: null,
  });

  useEffect(() => {
    fetchMaintenanceHistory();
  }, [equipmentId]);

  const fetchMaintenanceHistory = async () => {
    try {
      const response = await axios.get(`${url}/api/equipment/getMaintenanceHistory?equipmentId=${equipmentId}`);
      setMaintenanceHistory(response.data);
      calculateStats(response.data);
    } catch (error) {
      console.error('Error fetching maintenance history:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (data) => {
    if (data.length === 0) return;

    const sortedByDate = [...data].sort((a, b) => new Date(b.serviceDate) - new Date(a.serviceDate));
    const lastRecord = sortedByDate[0];

    setStats({
      totalServices: data.length,
      averageMileage: Math.round(data.reduce((acc, curr) => acc + curr.currentMileage, 0) / data.length),
      lastService: lastRecord.serviceDate,
      nextServiceDue: lastRecord.nextServiceMileage,
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Maintenance History
        </Typography>
      </Paper>

      {maintenanceHistory.length > 0 && (
        <Grid container spacing={2} className={classes.summaryCards}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <BuildIcon className={classes.cardIcon} />
                <Typography variant="h6">{stats.totalServices}</Typography>
                <Typography color="textSecondary">Total Services</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <SpeedIcon className={classes.cardIcon} />
                <Typography variant="h6">{stats.averageMileage.toLocaleString()}</Typography>
                <Typography color="textSecondary">Average Service Mileage</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <CalendarIcon className={classes.cardIcon} />
                <Typography variant="h6">{moment(stats.lastService).format('MM/DD/YYYY')}</Typography>
                <Typography color="textSecondary">Last Service Date</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <TimerIcon className={classes.cardIcon} />
                <Typography variant="h6">{stats.nextServiceDue?.toLocaleString()} mi</Typography>
                <Typography color="textSecondary">Next Service Due</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 50 }} />
              <TableCell>Service Date</TableCell>
              <TableCell>Current Mileage</TableCell>
              <TableCell>Next Service</TableCell>
              <TableCell>Services Performed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {maintenanceHistory.length > 0 ? (
              maintenanceHistory.map((record) => (
                <MaintenanceRow key={record._id} record={record} classes={classes} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className={classes.noRecords}>
                  <BuildIcon style={{ fontSize: 40, opacity: 0.5, marginBottom: 8 }} />
                  <Typography variant="body1">
                    No maintenance records found for this vehicle
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const formatLabel = (serviceKey) => {
  // console.log('Format label', serviceKey)
  let label = serviceKey
  let trimmed = serviceKey.replace(/([A-Z])/g, ' $1').trim()
  // console.log('Trimmed Item', trimmed)

  let firstLetter = trimmed?.charAt(0)?.toUpperCase()
  // console.log('first letter', firstLetter)
  let restOfWord = trimmed?.toString()?.slice(1, trimmed.length)

  // console.log('rest of', restOfWord)
  label = `${firstLetter}${restOfWord}`
  // console.log('label', label)
  return label
}
export default EquipmentMaintenanceHistory;
