import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Box,
  CircularProgress,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Person, Schedule, Work, NavigateNextOutlined, NavigateBeforeOutlined, CloseOutlined  } from '@material-ui/icons';
import AssignmentConfirmationDialog from './AssignmentConfirmationDialog';
import { isEven } from '../../../services/common'
import moment from 'moment'

const convertMinutesToHours = (mins) => {
  if (!mins) mins = 0
  let hours = mins / 60
  hours = Math.round(hours * 100) / 100
  return hours
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    // maxHeight: '50vh',
    height: '100%', // '60vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  crewInfo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    // backgroundColor: 'yellow'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  crewName: {
    marginLeft: theme.spacing(1),
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-between',
    // padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  currentStatsContainer: {
    backgroundColor: '#eee',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    width: '100%',
    padding: 3
  },
  stopsList: {
    flexGrow: 1,
    overflow: 'auto',
    height: '20vh',
    // backgroundColor: 'purple',
    padding: theme.spacing(1),
    '&::-webkit-scrollbar': {
      width: '4px', // Make the scrollbar skinny (adjust the width as needed)
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)', // Thumb color (scrollbar handle)
      borderRadius: '10px', // Rounded corners for the scrollbar handle
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Track color (scrollbar background)
    },
    '-ms-overflow-style': '-ms-autohiding-scrollbar',  // Thin scrollbar for IE and Edge
    'scrollbar-width': 'thin',     // Thin scrollbar for Firefox
  },
  stopItem: {
    marginBottom: theme.spacing(1),
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  highlightedStop: {
    backgroundColor: theme.palette.action.selected,
  },
  footer: {
    // position: 'relative',
    // bottom: 20,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  statsContainer: {
    height: '85%',
    // backgroundColor: 'pink',
    overflow: 'hidden',
    padding: 2
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    margin: 10,
    backgroundColor: '#fff'
  },
  infoItem: {
    marginBottom: theme.spacing(1),
  },
  infoValue: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  infoLabel: {
    fontSize: '0.65rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
    textAlign: 'center'
  },
}));

const calculateWeeklyHours = (weeklyRoutes, crewId) => {
  // console.log('Calculate Weekly Hours', weeklyRoutes)
  console.log(crewId)
  let weeklyHours = {
    serviceTime: 0,
    driveTime: 0
  }

  weeklyRoutes.forEach(dayRoute => {
    dayRoute.routes.forEach(route => {
      if (crewId === route.crewId) {
        // console.log('Route 89', route)
        const serviceTime = route.totalServiceTime / 60; // Convert minutes to hours
        const driveTime = route.totalDriveTime / 60; // Convert minutes to hours
        weeklyHours.serviceTime += serviceTime;
        weeklyHours.driveTime += driveTime;
      }
     
    });
  });
  // console.log('Caluclated Weekly Hours', weeklyHours)
  return weeklyHours;
};

const ActiveRouteDetailsIfIncluded = ({ activeRouteDetails, withThisJobHours, withThisJobWeeklyHours, classes, crewRoute}) => (
  <div className={classes.currentStatsContainer}>
         <InfoItem 
          label="Hours w/ This Job" 
          value={`${Math.round(withThisJobHours * 100) / 100}`}
          centerItem
        />
        <InfoItem 
          label="Week Total" 
          value={`${Math.round(withThisJobWeeklyHours * 100) / 100}`}
          centerItem
        />
         <InfoItem 
          label="Miles w/ This Job" 
          value={`${Math.round((activeRouteDetails?.totalMilesDriven || 0) * 100) / 100}`}
          centerItem
        />
   
  </div>
 
);

const InfoItem = ({ label, value, centerItem }) => {
  const classes = useStyles();
  return (
    <div className={classes.infoItem}>
      <Typography className={classes.infoValue} style={ centerItem ? { textAlign: 'center' } : null }>{value}</Typography>
      <Typography className={classes.infoLabel}>{label}</Typography>
    </div>
  );
};

const SelectedCrewDetails = ({ 
  crew, 
  stops, 
  date, 
  activeMarker, 
  jobSiteDetails, 
  handleUnassignment, 
  handleAssignToCrew, 
  crews, 
  markers, 
  activeRouteDetails, 
  handleClickOnScheduledSite, 
  highlightedSite,
  crewRoute,
  weeklyRoutes,
  loading,
  handleChangeCrew,
  closeCrewDetails
}) => {
  const classes = useStyles();
  const [isAssignmentDialogOpen, setIsAssignmentDialogOpen] = useState(false);
  // console.log('95 crews', crews)
  const openAssignmentDialog = () => setIsAssignmentDialogOpen(true);
  const closeAssignmentDialog = () => setIsAssignmentDialogOpen(false);
  const handleConfirmAssignment = (assignmentDetails) => handleAssignToCrew(assignmentDetails);
  console.log('188 stops', stops)
  let useActiveRoute = activeRouteDetails?.stops?.length
  // console.log('Active Route details', activeRouteDetails)
  let crewStops = [] // useActiveRoute ? stops : stops.filter(site => site.crew.toString() === crew._id.toString());
  
  if (crewRoute?.stops?.length) {
    let crewRouteStops = []
    crewRoute.stops.forEach(routeStop => {
      // console.log('Find the stop associated w/ this ', routeStop)
      let associatedStop = stops.find(stopItem => (stopItem.siteId === routeStop.siteId))
      // console.log('this is thte stop', associatedStop)
      if (associatedStop?._id) {
        crewRouteStops.push(associatedStop)
      } else {
        // console.log('Not in our stops...check markers', markers)
        associatedStop = markers.find(stopItem => (stopItem._id === routeStop.siteId))
        // console.log('This is our guy:', associatedStop)
        if (associatedStop?._id) {
          let mergedItem = {
            ...associatedStop,
            ...routeStop
          }
          // console.log('Use thi', mergedItem)
          crewRouteStops.push(mergedItem)
        } else {
          console.log('Still cant find a matching stop...', routeStop)
        }
      }
    })
    // console.log('Merged Crew Stops', crewRouteStops)
    crewStops = crewRouteStops
  } else {
    console.log('No Crew route...do we have an active route??', activeRouteDetails)
    // crewStops = activeRouteDetails.stops
  }

  const handleClickPreviousCrew = ( )=> {
    console.log('prev crew')
    handleChangeCrew('prev')
  }

  const handleClickNextCrew = ( )=> {
    console.log('next crew')
    handleChangeCrew('next')
  }
  
  crewStops = crewStops.sort((a, b) => a.order - b.order)
  let totalHoursWDriveTime = (activeRouteDetails?.totalServiceTime && activeRouteDetails?.totalDriveTime) ? convertMinutesToHours(Number(activeRouteDetails.totalServiceTime) + Number(activeRouteDetails.totalDriveTime)) : ''
  // console.log('Total Hours w/ Drive Time', totalHoursWDriveTime)
  const totalHours = crewStops.reduce((sum, stop) => sum + Number(stop.estimatedHours || 0), 0);
  const withThisJobHours = totalHoursWDriveTime || 0 // totalHours + Number(activeMarker?.estimatedHours || 0);
  // console.log('Weekly Routes for selected crew', weeklyRoutes)
  const crewWeeklyHours = calculateWeeklyHours(weeklyRoutes, crew._id)
  // console.log('Crew Weekly Hours', crewWeeklyHours)
  const withThisJobWeeklyHours = crewWeeklyHours?.serviceTime + Number(activeMarker?.estimatedHours || 0);
  const withThisJobWeeklyHoursIncludingDriveTime = crewWeeklyHours?.driveTime + Number(withThisJobWeeklyHours);
  // console.log('CrewStops 99', crewStops)
  // console.log('Crew:', crew)
  const currentTotalTime = Number(crewRoute?.totalDriveTime) + Number(crewRoute?.totalServiceTime)
  // console.log('Total Time w/o this new job', currentTotalTime)
  const hoursWithoutThisJob = convertMinutesToHours(currentTotalTime)
  // console.log('Active Route details', activeRouteDetails)
  const milesWithoutThisJob = crewRoute?.totalMilesDriven || '' // Math.round(activeRouteDetails?.totalMilesDriven || 0 * 100) / 100
  // console.log("Miles", milesWithoutThisJob)
  // console.log('Crew Route:', crewRoute)
  const dayOfWeek = moment(date).format('dddd')
  let weekOfTheYear = moment(date).week()
  let evenWeek = isEven(weekOfTheYear)
  let thisWeek = evenWeek ? 'Even' : 'Odd'
  return (
    <Paper className={classes.root} elevation={3}>
      <div style={{ maxHeight: '64px', width: '100%', backgroundColor: crew.color }} >&nbsp;
          <IconButton onClick={closeCrewDetails} edge='end' alt="Show All Crews" aria-label="Show All Crews">
              <CloseOutlined />
            </IconButton>
      </div>
      <div className={classes.header}>
        {/* <div className={classes.closeIcon}>
          <IconButton onClick={closeCrewDetails} edge='end' alt="Show All Crews" aria-label="Show All Crews">
              <CloseOutlined />
            </IconButton>
        </div> */}
        <div className={classes.crewInfo}>
          <Tooltip title="Previous Crew">
            <IconButton onClick={handleClickPreviousCrew} edge='end' alt="Prev Crew" aria-label="Prev Crew">
              <NavigateBeforeOutlined />
            </IconButton>
          </Tooltip>
          <div className={classes.row}>
            
              <Person style={{ color: crew.color }} />
              <Typography variant="h6" className={classes.crewName}>
                {crew.name} {crew?.foremanName ? `(${crew.foremanName})` : ''}
              </Typography>
           
            </div>
            <Tooltip title="Next Crew">
              <IconButton onClick={handleClickNextCrew} edge='end' alt="Next Crew" aria-label="Next Crew">
              <NavigateNextOutlined />
              </IconButton>
            </Tooltip>
       
          </div>
        
        <Typography variant="subtitle2">
          Date: {date.toLocaleDateString()} ({dayOfWeek} - {thisWeek})
        </Typography>
      </div>
      {
        loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress style={{ color: crew.color  } } size={ 64 } />
          </div>
        ) : (
    <div className={classes.statsContainer}>
      <div className={classes.stats}>
        <div className={classes.currentStatsContainer}>
            <InfoItem 
              label="Current Total Hours" 
              value={`${Math.round(hoursWithoutThisJob * 100) / 100}`}
              centerItem
            />
            <InfoItem 
              label="Miles" 
              value={`${milesWithoutThisJob}`}
              centerItem
            />
            <InfoItem 
              label="Stops" 
              value={`${crewStops.length}`}
              centerItem
            />
        </div>
       
      
        {activeMarker?.isUnassigned && (
          <ActiveRouteDetailsIfIncluded 
            activeRouteDetails={activeRouteDetails}
            crewRoute={crewRoute}
            withThisJobHours={withThisJobHours}
            withThisJobWeeklyHours={withThisJobWeeklyHoursIncludingDriveTime}
            classes={classes}
          />
        )}
      </div>
     
      <List className={classes.stopsList}>
        {crewStops.map((site, index) => (
          <ListItem 
            key={index}
            button
            onClick={() => handleClickOnScheduledSite(site)}
            className={`${classes.stopItem} ${highlightedSite === site._id ? classes.highlightedStop : ''}`}
          >
            <ListItemText
              primary={site.name}
              secondary={
                <React.Fragment>
                  <Typography variant="body2" component="span">
                    {site.address} - {site.estimatedHours}hrs
                    {site.distance ? ` (${site.distance}mi)` : ''}
                  </Typography>
                  <br />
                  <Chip 
                    size="small" 
                    label={`${site.frequency}${site.frequency === 'Bi-Weekly' ? ` ${site.evenOrOddWeeks}` : ''}`}
                    color="primary"
                  />
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleUnassignment(site)}
              >
                Un-Assign
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <div className={classes.footer}>
        {activeMarker?._id && (
          <Button
            variant="contained"
            color="primary"
            onClick={openAssignmentDialog}
            startIcon={<Work />}
          >
            Assign
          </Button>
        )}
      </div>
      </div>
        )
      }
      <AssignmentConfirmationDialog
        open={isAssignmentDialogOpen}
        onClose={closeAssignmentDialog}
        crew={crew}
        site={activeMarker}
        selectedDate={date}
        onConfirm={handleConfirmAssignment}
        jobSiteDetails={jobSiteDetails}
        markers={markers}
      />
    </Paper>
  );
};

export default SelectedCrewDetails;